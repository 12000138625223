.heart-beat-top {
  animation: heart-beat-top 3s ease-out infinite;
  animation-fill-mode: forwards;
}
.heart-beat-bottom {
  animation: heart-beat-bottom 3s ease-out infinite;
  animation-fill-mode: forwards;
}

@keyframes heart-beat-top {
  0% {
    opacity: 0.3;
  }
  25% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.3;
  }
}
@keyframes heart-beat-bottom {
  0% {
    opacity: 0.3;
  }
  25% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.3;
  }
}
